import { Injectable } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { User } from '@xpo-ltl/sdk-common';
import * as _ from 'lodash';
import { ConfigManagerProperties } from '../../enums/config-manager-properties.enum';
import UserRoleHelper from '../../enums/user-role/user-role-helper';
import { UserRole } from '../../enums/user-role/user-role.enum';

@Injectable({ providedIn: 'root' })
export class UserRoleService {
  user: User;
  private isProduction = false;
  private roleNames = {};
  private readonly removeDomainRegExp = /.*\//gm;

  constructor(private configManagerService: ConfigManagerService) {
    this.isProduction =
      this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion) === 'local-version';

    /** TODO: establish roles for PROD / NON-PROD */
    this.roleNames = {
      [UserRole.User]: this.isProduction ? 'CIS_DEV' : ['CIS_DEV'],
      [UserRole.SuperUser]: this.isProduction ? 'DEFAULT_TABIGATOR' : ['DEFAULT_TABIGATOR'],
    };
  }

  /** TODO: an authorized user can have any set of roles defined above... */
  isAuthorizedUser(user: User): boolean {
    return (
      this.hasRole(user.roles, this.roleNames[UserRole.User]) ||
      this.hasRole(user.roles, this.roleNames[UserRole.SuperUser])
    );
  }

  /** TODO: some authorized users may have mutliple roles... see role-check-guard.ts as it handles this!!! */
  hasMultipleRoles(user: User): boolean {
    return (
      this.hasRole(user.roles, this.roleNames[UserRole.User]) &&
      this.hasRole(user.roles, this.roleNames[UserRole.SuperUser])
    );
  }

  get isBasicUser(): boolean {
    return this.hasRole(this.user.roles, this.roleNames[UserRole.User]);
  }

  get isSuperUser(): boolean {
    return this.hasRole(this.user.roles, this.roleNames[UserRole.SuperUser]);
  }

  private hasRole(userRoles: string[], roleOf: any): boolean {
    const roleOfArray = _.castArray(roleOf);
    const splitUserRolesArr = _.map(userRoles, (role: string) => {
      return role.replace(this.removeDomainRegExp, '');
    });
    const results = _.intersectionBy(splitUserRolesArr, roleOfArray, (value) => value.toUpperCase());
    return !!results && !!results.length;
  }

  setRole(role: UserRole) {
    if (!!this.user && !this.configManagerService.getSetting<boolean>(ConfigManagerProperties.production)) {
      const roleEnum = UserRoleHelper.toEnum(role);
      if (!!roleEnum) {
        this.user.roles.length = 0;
        _.castArray(this.roleNames[roleEnum]).forEach((roleName) => this.user.roles.push(roleName));
      }
    }
  }

  getRole(): UserRole {
    if (this.hasRole(this.user.roles, this.roleNames[UserRole.User])) {
      return UserRole.User;
    }
    if (this.hasRole(this.user.roles, this.roleNames[UserRole.SuperUser])) {
      return UserRole.SuperUser;
    }
  }
}
