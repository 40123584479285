import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';
import { NotAuthorizedComponent } from './dialogs/not-authorized/not-authorized.component';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { NotAuthorizedRedirectGuard } from './shared/guards/not-authorized-redirect/not-authorized-redirect.guard';

const routerDefinitions: Routes = [
  {
    path: AppRoutes.NOT_AUTHORIZED_PAGE,
    component: NotAuthorizedComponent,
    canActivate: [NotAuthorizedRedirectGuard],
  },
  {
    path: '',
    redirectTo: 'module-library-workbench',
    pathMatch: 'full',
  },
  {
    path: 'module-library-workbench',
    loadChildren: () =>
      import('./module-library-workbench/module-library-workbench.module').then(
        (mod) => mod.ModuleLibraryWorkbenchModule
      ),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: 'module-groups-workbench',
    loadChildren: () =>
      import('./module-groups-workbench/module-groups-workbench.module').then((mod) => mod.ModuleGroupsWorkbenchModule),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: 'module-group-management',
    loadChildren: () =>
      import('./module-group-management/module-group-management.module').then(
        (mod) => mod.ModuleGroupManagementWorkbenchModule
      ),
    canActivate: [XpoAuthenticationGuard],
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routerDefinitions)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
