import { Component, ViewEncapsulation } from '@angular/core';

import { invoke as _invoke, isEmpty as _isEmpty, toLower as _toLower } from 'lodash';

/* XPO */
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { XpoAccountPopoverConfig, XpoMessagingPopoverMessage, XpoShellRoute } from '@xpo-ltl/ngx-ltl-core';
import { User } from '@xpo-ltl/sdk-common';

/* Rxjs */
import { Observable, of } from 'rxjs';
import { delay, retryWhen, take } from 'rxjs/operators';

/* Enums */
import { AccountUrls } from './shared/enums/account-urls.enum';
import { AppRoutes } from './shared/enums/app-routes.enum';
import { ConfigManagerProperties } from './shared/enums/config-manager-properties.enum';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  title: string;

  build: string;
  region: string;

  routes: XpoShellRoute[];
  accountPopoverConfig: XpoAccountPopoverConfig;
  messageData: Observable<XpoMessagingPopoverMessage[]>;
  isLoggedInUser = false;

  get accountUrls() {
    return AccountUrls;
  }

  constructor(
    private configManagerService: ConfigManagerService,
    private loginService: XpoLtlLoggedInUserService,
    private authService: XpoLtlAuthenticationService
  ) {
    /** Shell setup */
    this.title = 'Batch Framework';
    this.build = configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
    this.region = `${_toLower(configManagerService.getSetting<string>(ConfigManagerProperties.region))}`;

    this.authService.initAuthSetup$(this.region).subscribe((info: RegionInfo) => {
      this.loggedInUserFunc();
    });

    this.messageData = of([]);

    this.loginService.userLoggedIn$.subscribe(() => {
      this.loggedInUserFunc();
    });

    this.routes = [
      {
        label: 'Module Library',
        path: `/${AppRoutes.MODULE_LIBRARY_WORKBENCH_PAGE}`,
      },
      {
        label: 'Module Groups',
        path: `/${AppRoutes.MODULE_GROUPS_WORKBENCH_PAGE}`,
      },
    ];
  }

  private loggedInUserFunc(): void {
    this.loginService
      .getLoggedInUser(this.configManagerService.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .pipe(retryWhen((errors) => errors.pipe(delay(1000), take(5))))
      .subscribe(
        (user: User) => {
          this.isLoggedInUser = true;
          // if (user) {
          // this.setDynatraceUserIdentity(user);
          // }
        },
        (error) => {
          console.log('ERROR', error);
        }
      );
  }
}
